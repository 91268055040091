<template>
  <div>
    <van-nav-bar left-arrow left-text="返回" @click-left="goBack" title="查看全部网点" />
    <!-- <input type="date"> -->
    <div style="padding:6px 16px 0px;background-color:#fff;margin-top:17px">
      <div class="tit">电子支付总金额：{{allMoney|numToFixed}}元</div>

    </div>
    <van-cell title="选择日期" :value="date" @click="show = true" is-link />
    <van-popup v-model="show" safe-area-inset-bottom position="bottom">
      <van-datetime-picker v-model="currentDate" type="year-month" title="" :formatter="formatter" @cancel='show=false' @confirm="onConfirm" />
    </van-popup>
    <div style="margin-top:18px;background-color:#fff;padding-top:6px">
      <van-row style="padding:0 16px">
        <van-col span="2"><span class="listTitle">排名</span></van-col>
        <van-col span="12"><span class="listTitle">网点</span></van-col>
        <van-col span="5" @click="insFn"><span class="listTitle">安装支付
            <van-icon :name="isIns?'arrow-down':'arrow-up'" />
          </span></van-col>
        <van-col span="5" @click="repairFn"><span class="listTitle">维修支付
            <van-icon :name="isrepair?'arrow-down':'arrow-up'" />
          </span></van-col>
      </van-row>
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <van-cell @click="websiteMonthFn(item.websit,item.websitName)" v-for="(item,i) in dataList" :key="i">
          <van-row>
            <van-col span="2">{{i+1}}</van-col>
            <van-col span="12">
              <div class="listTitle">{{item.websitName}}</div>
            </van-col>
            <van-col span="5">
              <div style="text-align:right">
                <div class="fr">{{item.insamount|numToFixed}}</div>
              </div>
            </van-col>
            <van-col span="5">
              <div style="text-align:right">
                <div class="fr">{{item.repamount|numToFixed}}</div>
              </div>
            </van-col>
          </van-row>
          <van-row>
            <van-col span="2"></van-col>
            <van-col span="12">
              <div class="listTitle">{{item.websit}}</div>
            </van-col>
            <van-col span="5">
              <div style="text-align:right">
                <div v-if="item.linsratea>0" class="upFloat"><img style="width:12px;height:12px" src="../assets/icon/shangsheng.png" alt="">{{item.linsratea}}%</div>
                <div v-if="item.linsratea<0" class="downFloat"><img style="width:12px;height:12px" src="../assets/icon/down.png" alt="">{{-item.linsratea}}%</div>
              </div>
            </van-col>
            <van-col span="5">
              <div style="text-align:right">
                <div v-if="item.lrepratea>0" class="upFloat"><img style="width:12px;height:12px" src="../assets/icon/shangsheng.png" alt="">{{item.lrepratea}}%</div>
                <div v-if="item.lrepratea<0" class="downFloat"><img style="width:12px;height:12px" src="../assets/icon/down.png" alt="">{{-item.lrepratea}}%</div>
              </div>
            </van-col>
          </van-row>
        </van-cell>
      </van-list>
    </div>
  </div>
</template>

<script>
import { getUrlKey } from "@/utils/common";
export default {
  data() {
    return {
      dataList: [],
      show: false,
      date: "",
      allMoney: 0,
      loading: false,
      finished: false,
      refreshing: false,
      isIns: false,
      isrepair: false,
      currentDate: new Date(),
    };
  },
  created() {
    this.date = this.$route.query.date;
  },
  methods: {
    //月报网点详情
    websiteMonthFn(value, name) {
      this.$router.push({
        path: "/analysis/websiteDetail",
        query: {
          time: this.date,
          website: value,
          name: name,
          type: "month",
        },
      });
    },
    //维修筛选
    repairFn() {
      this.isrepair = !this.isrepair;
      if (this.isrepair) {
        this.dataList.sort((a, b) => {
          return b.repamount - a.repamount;
        }); /* 从大到小 */
      } else {
        this.dataList.sort((a, b) => {
          return a.repamount - b.repamount;
        }); /* 从小到大 */
      }
      // this.isrepair = false;
    },
    //安装筛选
    insFn() {
      this.isIns = !this.isIns;
      if (this.isIns) {
        this.dataList.sort((a, b) => {
          return b.insamount - a.insamount;
        }); /* 从大到小 */
      } else {
        this.dataList.sort((a, b) => {
          return a.insamount - b.insamount;
        }); /* 从小到大 */
      }
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    onLoad() {
      this.getList();
    },
    //获取数据
    getList() {
      let params = {
        openid: getUrlKey("openid", window.location.href),
        moduleId: "111111",
        type: "家用",
        startYearMonth: this.date,
        endYearMonth: this.date,
        website: "",
        sumType: 1,
      };
      this.postRequest("/wechat-analysis/fees-month", params).then((res) => {
        let money = 0;
        res.data.forEach((v) => {
          money = money + v.insamount + v.repamount;
        });
        this.allMoney = money;
        if (this.refreshing) {
          this.dataList = [];
          this.refreshing = false;
        }
        if (res.data.length === 0) {
          this.dataList = [];
          this.finished = true;
        }
        this.dataList = res.data;
        this.insFn();
        this.loading = false;
        if (this.dataList.length >= res.data.length) {
          this.finished = true;
        }
      });
    },
    onConfirm(date) {
      this.show = false;
      this.date = this.formatDate(date);
      this.getList();
    },
    //日期格式化
    formatDate(date) {
      return `${date.getFullYear()}-${
        date.getMonth() + 1 >= 10
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1)
      }`;
    },
    //日期格式化
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.listTitle {
  font-weight: 700;
  font-size: 12px;
}
.tit {
  font-weight: 700;
  font-size: 14px;
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>